.Offers{
    top:0;
    left:0;
    background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url('./offer.png');
    background-position:center;
    background-attachment: fixed;
    height:65vh;
    color:#fff;
    width:100vw;
}
@media screen and (max-width:660px){
    .Offers{
        height:40vh;
    }
}
.OffersHead{
    padding-top:10vh;
   margin:10px auto;
    font-size:150%;
    display: inline-block;
    border-bottom:2px solid #14a76c;
}
.Offers a{
    text-decoration: none;
    color:#fff;
    padding:0 10px;
}
.Offers .active{
    color:#14a76c;
}