
.flip-card {
    background-color: transparent;
    width: 80vw;
    height: 75vh;
    -webkit-perspective: 1000px;
    margin:10px auto;
    perspective: 1000px;
  }
  @media screen and (max-width:660px){
    .flip-card{

      height:80vh;
    }
  }
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    color:#fff;
    background-image: linear-gradient(to right,rgba(20, 167, 108, 0.3)  ,rgba(13, 106, 69, 0.3)), url("https://blog.ihg.com/wp-content/uploads/2018/02/street-food-japan.png");
    background-position: center;
    background-size: cover;
    z-index: 2000;
  }
  /* rgba(255, 101, 47, 0.4),rgba(234, 61, 0, 0.4) */
  
  .flip-card-back {
    color:#fff;
    background:linear-gradient(to right,#ff652f,rgba(166, 43, 0, 1));
    transform: rotateY(180deg);
    z-index: 1000;
  }
  ul{
    margin-top:10%;
  }
  li{
    line-height: 145%;
    letter-spacing: 1px;
    word-spacing: 2px;
    margin:10px 5px;
  }
  .flip-card-back a:link,.flip-card-back a:visited{
    padding-top:60%;
    text-decoration: none;
    color:#fff;
    background-color:#14a86c;
    border-radius: 200px;
    border:0;
    padding:7px 20px;
  }
  .flip-card-back a:hover,.flip-card-back a:active{
    background-color: #0d6a45;
    box-shadow: 0 10px 10px #ea3d00;
  }
  .flip-card-front h1,h2,h3,h4{
    margin:10px auto;
    letter-spacing: 2px;
    font-weight: 200;
    word-spacing: 3px;
    line-height: 145%;
  }
  .flip-card-front h1{
    font-size:300%;
    font-weight: 100;
  }
  .flip-card-front h2{
    font-size:220%;
    text-transform: capitalize;
  }
  .flip-card-front h3{
    font-size: 140%;
    font-weight: 300;
  }
