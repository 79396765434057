.single{
    height: 40px;
    width:auto;
    display: inline-block;
    border-radius: 200px;
    border:1px solid #ff652a;
    color:#ff652a;
    padding:5px 20px;
    text-align: center;
    margin:6px 10px;
    text-transform: uppercase;
    cursor:pointer;
}
.single:hover{
    background-color: #ff652a;
    color:#fff;
}
