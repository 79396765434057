*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: 'Lato','Ariel',sans-serif;
  font-size:20px;
  margin:0;
  padding:0;
  z-index: 100;
  overflow-x: hidden;
}
.App a{
  text-decoration: none;
}
@media screen and (max-width:770px){
  .App{
    font-size: 15px;
  }
}
.toolbar{
    font-size:100%;
    display: inline-block;
    padding-top:15px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    
}
.back{
    background-color: #242424;
}
.toolbar a{
    text-decoration: none;
    color:#fff;
}
.toolbar:hover{
    border-bottom:1px solid #fff;
    /* border-left:1px solid #747474;
    border-right: 1px solid #747474; */
    padding:0 10px;
}
.toolbar:hover img {
    color:green;
}
.toolbar img{
    height:30px;
    width:auto;
}
.t-ext{
    /* background-color: #272727; */
    color:#fff;
    height:90px;
    font-weight: 400;
    position: -webkit-sticky;
    position: sticky;
}
.number{
    padding-top:13px;
    font-size:140%;
    color:#fff;
    float: right;
    -webkit-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
}
.number:hover{
    color: #14a76c;
}
.convo{
    display: inline-block;

    padding-right:2px;
}
.count{
    margin-left:5px;
    padding:2px 5px;
    text-align: center;
    font-size:70%;
    background-color:#747474;
    border-radius: 100px;
    border:1px solid #747474;
}
.short{
    z-index:2000;
    display: none;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    cursor: pointer;
    position: fixed;
    top:0;
    left:0;
}
.contentTool{
    height:100vh;
    width:60vw;
    color: #ff652f;
    top:0;
    z-index:2000;
    background-color:#242424;
    left:0;
    opacity: 0;
    display:none;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
}

.contentShow{
    display: block;
    -webkit-transform: translateX(-180);
            transform: translateX(-180);
    opacity: 1;
}
.smallImgBox{
    height:50px;
    width:auto;
    float:right;
}
.smallImgBox img{
    height:100%;
    width:auto;
}

.items{
    padding-top:40%;
}
.eachOne{
    margin-top:30px;
}
.eachOne:hover{
    padding:10px 0;
    background-color:#0b0b0b;
}
.linkContainer{
    padding-top: 13px;
}
.linkContainer a{
    text-decoration: none;
    color:#ff652f;
    font-size: 130%;
    float:left;
}
.short img{
    float: left;
    padding-top:10px;
    padding-left:15px;
}
@media screen and (max-width:770px){
    .t-ext{
        display: none;
    }
    .short{
        display: block;
    }
}

.backdrop{
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    opacity: 0.8;
    background-color: #242424;
    z-index:500;
    color:#fff;
}
.Header{
    background-image:-webkit-linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)) , url(/static/media/vlad.08b712fa.jpg);
    background-image:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)) , url(/static/media/vlad.08b712fa.jpg);
    height: 100vh;
    background-attachment: fixed;
    width: 100%;
    background-position: center;
    background-size: cover;
}

.controls{
    padding:5px 15px;
    position: absolute;
    top:90vh;
}
.circle{
    display: inline-block;
    margin:0 3px;
    height: 17px;
    width:17px;
    border-radius: 50%;
    border:1px solid #ff652f;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
    cursor: pointer;
}
.circle:hover{
    background-color: #ff652f;
}
 .full{
    display: inline-block;
    margin:0 3px;
    height: 17px;
    width:17px;
    border-radius: 50%;
    border:1px solid #ff652f;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
    cursor: pointer;
    background-color: #ff652f;
}
.internal-c{
    padding-left:45vw;
}
.button-offer{
    margin-left:70vw;
}
.sec-tiles{
    margin-top: 60px;
} 
.specs{
    margin-top:70px;
    background-color: #f5f5f5;
    padding-bottom:20px;
}
.specOffer{
    margin-bottom:50px;
}
.sec-map{
    margin-top:80px;
    display: block;
    padding-bottom:75vh;
    position: static;
}
@media screen and (max-width:1170px){
    .Header{
        background-size: contain;
        background-image:-webkit-linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)) , url(/static/media/vlad-img.16ed31e3.jpg);
        background-image:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)) , url(/static/media/vlad-img.16ed31e3.jpg);
    }
} 
@media screen and (max-width:700px){
    .Header{
        background-size: contain;
        background-image:-webkit-linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)) , url(/static/media/vlad.08b712fa.jpg);
        background-image:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)) , url(/static/media/vlad.08b712fa.jpg);
    }
} 
.brand{
    display: inline-block;
    background-color: #242424;
    opacity: 0;
    z-index:50;
    border:1px solid #242424;
    height: 0;
    width: 0;
    padding:20px 60px;
    overflow: hidden;
    border-radius: 250px;
    margin-top:12vh;
    /* animation: 0.6s show; */
    -webkit-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out; 
}
.show{
    opacity: 0.9;
    -webkit-transform: translateX(100);
            transform: translateX(100);
    height: auto;
    width: auto;
}
@-webkit-keyframes show{
    0%{opacity:0;height:0;width:0}
    25%{opacity: 0.3;height:700px;width:40px}
    50%{opacity: 0.6;height: 100px;width:60px}
    100%{opacity: 0.9;height:auto;width:auto}
}
@keyframes show{
    0%{opacity:0;height:0;width:0}
    25%{opacity: 0.3;height:700px;width:40px}
    50%{opacity: 0.6;height: 100px;width:60px}
    100%{opacity: 0.9;height:auto;width:auto}
}
.brandnum{
    display: inline-block;
    color: #14A76C;
    font-weight: bold;
    font-size:600%;
    padding-right:10px;
}
.name{
    font-size:320%;
    vertical-align: text-bottom;
    font-weight: 300;
    text-shadow:2px  #FFE400;
    color:#FFE400;
    display: inline-block;
}
@media screen and (max-width:550px){
    .name{
        font-size: 280%;
        
    }
    .brandnum{
        font-size:510%;
    }
    .brand img{
        margin-top:30px;
    }
}
.brand img{
    height:100%;
    width: auto;
    padding-top:20px;
    display: inline-block;
}
.image-container{
    margin: -100px;
    padding:0;
    height:90px;
    display:block;
    width:auto;
}
.lastTitle{
    display: inline-block;
    padding-top:110px;
    padding-left:150px;
    font-weight: 400;
    color:#14A76C;
    font-size:130%;
}
.grab{
    height:50px;
    width:auto;
    border-radius: 200px;
    border:1px solid #ff652f;
    padding:10px 25px;
    text-align: center;
    background-color: #ff652f;
    display: inline-block;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    cursor:pointer;
}
.grab a{
    color:#fff;
    text-decoration:none;
}
.grab p{
    color:#fff;
    letter-spacing: 1px;
    font-weight: 300;
    font-size: 110%;
}
.grab:hover,.grab:active{
    background-color:#d93800;
    border:1px solid #d93800;
}
@media screen and (max-width:1170px){
    .grab{
        min-width:60px;
        padding:7px 8px;
        height: 40px;
    }
}
.tile{
    height:270px;
    width:auto;
    margin-top:30px;
    min-width:200px;
    padding:20px 10px;
    display: inline-block;
    background-image: -webkit-linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/green_burger.38c7ac15.jpg);
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/green_burger.38c7ac15.jpg);
    color:#fff;
    background-position: center;
    background-size: cover;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.nd1{
    background-image: -webkit-linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/green_burger.38c7ac15.jpg);
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/green_burger.38c7ac15.jpg);
}
.nd2{
    background-image: -webkit-linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/contact_us.90c43111.jpg);
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/contact_us.90c43111.jpg);
}
.nd3{
    background-image: -webkit-linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/birthday.f11a6e5a.jpg);
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/birthday.f11a6e5a.jpg);
}
.of1{
    background-image: -webkit-linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/offerpic.3b8d7c24.jpg);
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/offerpic.3b8d7c24.jpg);
}
.of2{
    background-image: -webkit-linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/offerpic2.98a31e01.jpeg);
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/offerpic2.98a31e01.jpeg);
}
.of3{
    background-image: -webkit-linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/offerpic3.32ee529e.jpg);
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/offerpic3.32ee529e.jpg);
}
.tile:hover{
    margin-top:0px;
    box-shadow: 0 20px 20px #747474;
}
.titleHead{
    padding-top:55px;
    text-transform: capitalize;
    font-weight: 300;
    font-size:140%;
}
.titleContent{
    text-transform: capitalize;
    word-spacing: 3px;
    letter-spacing: 2px;
    font-size: 120%;
    font-weight: 400;
}
.titleButton{
    margin-top:0px;
    float: right;
    padding-right: 10px;
}
.spec{
    height:auto;
    padding-top: 10px;
    margin-top:10px auto;
    width:100%;
    min-width: 200px;
}
.spec img{
    margin-bottom:4px;
    height:80px;
    width:auto;
}
.specHead{
    font-size:120%;
    text-transform: capitalize;
    word-spacing: 2px;
}
.spec hr{
    display: inline-block;
    width:200px;
    background-color: #ff652f;
}
.specContent{
    align-content: center;
    width:100%;
    margin:0 auto;
    font-size: 80%;
    color:#747474;
    line-height: 145%;
    word-spacing: 2px;
}
@media screen and (min-width:760px){
    .specContent{
        font-size:100%;
    }
    .spec{
        min-width:25px;
    }
}
@media screen and (max-width:500px){
    .spec{
        height:200px;
        margin-top:30px;
        padding-top:0;
    }
}
.specOffer{
    display: inline-block;
    height:100vh;
    width:100%;
    background-image:-webkit-linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.5)) ,url(/static/media/pizzaParty.5baccd35.jpg);
    background-image:linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.5)) ,url(/static/media/pizzaParty.5baccd35.jpg);
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}
.offerArrange{
    margin-top:20vh;
}
.offerHead{
    font-size:280%;
    color:#ffe400;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.offerContent{
    font-size:160%;
    letter-spacing: 2px;
    color:#fff;
    text-transform: capitalize;
}
.offerStarting{
    display: inline;
    font-size: 150%;
    letter-spacing: 1px;
    color:#ffe400;
    font-weight: 400;
    text-transform: capitalize;
}
.offerDigit{
    font-size:250%;
    color:#14a76c;

}

/* .offerButton{

} */
.stacked {
    margin-top:30px;
    height:200px;
    width: auto;
    min-width:150px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.stacked img{
    -webkit-perspective: 1000;
            perspective: 1000;
    opacity: 0.8;
    height:100%;
    box-shadow: 0 5px 5px #5f5f5f;
    width:100%;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.stackedTop{
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
    display: inline-block;
    padding:4px 10px;
    background-color: #14a76c;
    font-size:170%;
    margin-bottom: 30px;
    color:#fff;
    box-shadow: 0 24px 24px #5f5f5f;
    font-weight: 400;    
    -webkit-transition: all ease 0.4s;    
    transition: all ease 0.4s;
}
.stackedTop:hover{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    color:#242424;
    
}
.stacked:hover{
    margin-top:0;
}
.stacked img:hover{

    box-shadow: 0 20px 20px #5f5f5f;
    opacity: 1;
    height:220px;
    z-index:300;
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
}
/* .stacked:hover{
    margin-top:0px;
} */

.stacked-container{
    margin-top:10px;
}
.footer{
    padding:30px;
    display: block;
    position: relative;
    background-color: #242424;
    height:auto;
    font-size:80%;
    width:100%;
    letter-spacing: 1px;
}
.footer img{
    height:25px;
    width:auto;
}
.footer a,.footer a:link,.footer a:visited{
    float: left;
    text-decoration: none;
    color: #727171;
}
.footer a:hover,.footer a:active{
    color:#fff;
}
.storage{
    float:left;
}
.storage a{
    padding:0 10px;
}
.storage2{
    float:right;
}
.storage2  img{
    margin:0 10px;
}
.footer-text{
    margin-top:30px;
    color:#727171;;
    letter-spacing: 1px;
    word-spacing: 3px;
    line-height: 165%;
}
.footer-text:hover{
    color:#fff;
}
@media screen and (min-width:600px){
    .footer{
        height:35vh;
    }
}
.menuHeader{
    top:0;
    left:0;
    height:60vh;
    background-attachment: fixed;
    width:100%;
    background-image: -webkit-linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(/static/media/jay.0c2e4622.jpg);
    background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url(/static/media/jay.0c2e4622.jpg);
    background-position: center;
    background-size: cover;
}
@media screen and (max-width:660px){
    .menuHeader{
        height:40vh;
        background-size: contain;
        background-image: -webkit-linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.5)),url(/static/media/jay.0c2e4622.jpg);
        background-image: linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.5)),url(/static/media/jay.0c2e4622.jpg);
    }
}
.menuTitle{
    padding-right:auto;
    padding-left: auto;
    margin-top:50px;
    font-size:300%;
    color:#fff;
    display: inline-block;
    border-bottom:2px solid #ff652f;
    font-weight: 300;
}
.menuHeader ul{
    list-style: none;
    margin:0 auto;
}
.menuHeader li a{
    text-decoration: none;
    color:#fff;
}
.menuHeader li .active{
    font-weight: bold;
    color:#14a76c;
}
.menuHeader li .active:hover{
    color:#fff;
}
.menuHeader li{
    font-size: 120%;
    letter-spacing: 1px;
    display: inline;
    padding:10px 10px;
}
.menu-options{

    margin-top:60px;
}
.menuAll{
    padding:20px auto;
}
.addCustomization{
    height:400px;
    color:#fff;
    width:70vw;
    background-image: -webkit-linear-gradient(left, #ff652f , #d93800);
    background-image: linear-gradient(to right, #ff652f , #d93800);
    z-index: 600;
    top:0;
    left:0;
    position: absolute;
}
.xyz{
    float:left;
}
.CustomizationHeader{
    font-size:140%;
}
input[select]{
    background-color: aqua;

}
.addCustomization button{
    margin:10px 10px;
    padding:5px 15px;
    border-radius: 200px;
}
.cross{
    font-size: 140%;
    color:#fff;
    float: right;
    cursor: pointer;
    -webkit-transition: ease 0.4s all;
    transition: ease 0.4s all;
    margin:0 10px;
    position:absolute;
}
.cross:hover{
    padding:4px 3px;
    font-size: 150%;
    box-shadow: 0 10px 10px #d93800;

}
.single{
    height: 40px;
    width:auto;
    display: inline-block;
    border-radius: 200px;
    border:1px solid #ff652a;
    color:#ff652a;
    padding:5px 20px;
    text-align: center;
    margin:6px 10px;
    text-transform: uppercase;
    cursor:pointer;
}
.single:hover{
    background-color: #ff652a;
    color:#fff;
}

.inOptions{
    margin-top:30px;
   background-color:#242424;
}
.inOptionsHead{
    color:#ff652f;
    margin:5px auto;
    text-transform: capitalize;
    font-size: 240%;
}
.inOption{
    margin:20px 7px;
    padding:10px 3px;
    height:auto;
    display: inline-block;
    min-height: 380px;
    -webkit-transition: 0.4s ease all;
    transition: 0.4s ease all;
    width:300px;
    /* min-width: 300px;
    max-width: 300px; */
    color:#fff;
    /* background-color: #fff; */
    background-image:-webkit-linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/backPizza.18d75ebc.jpg);
    background-image:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/static/media/backPizza.18d75ebc.jpg);
    background-position: center;
    background-size: cover;
    box-shadow: 0 10px 10px #0b0b0b;
}
.inOption:hover{
    box-shadow: 0 20px 20px #0b0b0b;
    
}

.inHead{
    text-transform: capitalize;
    font-size: 140%;
}
.inContent{
    margin:4px auto;
    letter-spacing: 1px;
    font-size:80%;
}
.inPrice{
    margin:0;
    font-weight: bold;
    color:#fff;
    font-size:120%;
}
.inImgBox{
    height:180px;
    width:auto;
    padding:0px 5px;
    margin:5px 10px;
}
.inImgBox img{
    height: 100%;
    width: 100%;
}
.customize{
    display: block;
    opacity: 0.3;
    background:-webkit-linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.7));
    background:linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.7));
    opacity: 0.8;
    color:#fff;
    cursor: pointer;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
}
.customize:hover{
    color:#ff652f;
    font-weight: bold;
}
.inOption a:link,.inOption a:visited{
    text-decoration:none;
    color:#fff;
    border-radius: 200px;
    padding:3px 10px;
    background-color: #ff652f;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    margin:0 7px;
}
.inOption a:hover,.inOption a:active{
    background-color: #ea3d00;
}
.inOption .active{
    background-color: #ff581e;
    color:#14a76c;
}
.showCustomize{
    height: 100%;
    position: static;
    width: 100%;
    opacity: 0.8;
    background-color:#fff;
}
.inOpButton{
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background-color:#ff652f;
    color:#fff;
    border:0;
    cursor: pointer;
    border-radius:200px;
    padding:1px 10px;
}
.inOpButton:hover{
    background-color: #ea3d00;
}
.Modal{
    position: absolute;
    height:auto;
    width:auto;
    top:0;
    margin:50% 15%;
    background-color: #fff;
    z-index:700;
}
@media screen and (max-width:660px){
    .Modal{
    margin:70% 15%;
    }
}
.Order{
    top:0;
    left:0;
    background-image: -webkit-linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(/static/media/serve.f2174f5f.png);
    background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url(/static/media/serve.f2174f5f.png);
    background-position: center;
    background-attachment: fixed;
    height:55vh;
    color:#fff;
    width:100vw;
}
@media screen and (max-width:660px){
    .Order{
        background-size: cover;
    }
}
.OrderHead{
   padding-top:10vh;
   margin:10px auto;
    font-size:150%;
    display: inline-block;
    border-bottom:2px solid #14a76c;
}
.Order a {
    margin:0 10px;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
}
.Order a:link,.Order a:visited{
    text-decoration: none;
    color:#fff;
}
.Order a:hover,.Order a:active{
    color:#14a76c;
    font-weight: bold;
}
.Order .active{
    color:#ff652f;
    
}
.order-sec{
    background:-webkit-linear-gradient(left,#14a76c,#0f7a4f);
    background:linear-gradient(to right,#14a76c,#0f7a4f);
}
@media screen and (max-width:660px){
    .OrderHead{
        font-size:180%;
    }
    .Order{
        height: 37vh;;
    }
}
.Items{
    background-color: #242424;
    padding:10px;
}
.Items a{
    margin-bottom:10px;
}
.ItemsHead{
    color:#fff;
    font-size:140%;
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom:5px auto;
}
.item {
    height:auto;
    width:100%;
    margin:10px 10px;
    
}
.item p{
    
    font-size:90%;
}
.itemHead{
    color:#ff652f;
}
.itemPrice{
    color:#14a76c;
}
.OrderForm {
  margin: 0 auto;
  padding-bottom: 10px;
}
.OrderForm button {
  background-color: #ff652f;
  color: #fff;
  border-radius: 5px;
  padding: 5px 15px;
  border: 0;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  cursor: pointer;
}
.OrderForm button:hover {
  box-shadow: 0 6px 6px #0b5b3b;
  background-color: #ea3d00;
}
.OrderForm form legend p {
  width: 100%;
  font-size: 80%;
  float: right;
  text-align: right;
  letter-spacing: 1px;
  color: #fff;
}
.form__container {
  width: 50%;
  margin: 0 auto;
  /* background-color: grey; */
}
.form__container {
  @media (max-width: 600px) {
    width: 75%;
  }
}
.OrderFormHead {
    padding-top: 15px;
  color: #fff;
  font-size: 24px;
}
.OrderForm form input {
  font-size: 80%;
  /* text-align: center; */
  padding: 3px 8px;
  border-radius: 5px;
  width: 80%;
  float: left;
  /* border:1px solid #ff652f; */
  border: none;
  margin-left: 30px;
}
.OrderForm form input:focus {
  outline: none;
  border: 1px solid #ff652f;
  background-color: #f5f5f5;
}
@media screen and (max-width: 660px) {
  .OrderForm form input {
    width: 155px;
  }
}
.OrderFormHead {
  text-transform: capitalize;
  font-size: 140%;
}
.OrderFormNotice {
  margin-top: 5px;
  color: #fff;
  font-size: 70%;
}
@media screen and (max-width: 660px) {
  .OrderForm form legend p {
    font-size: 60%;
  }
}

.Cart{
    top:0;
    left:0;
    background-image: -webkit-linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(/static/media/cart.56cff607.jpg);
    background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(/static/media/cart.56cff607.jpg);
    background-position:center;
    background-attachment: fixed;
    height:65vh;
    color:#fff;
    width:100vw;
}
@media screen and (max-width:660px){
    .Cart{
        height:40vh;
        background-size: contain;
    }
}
.CartHead{
    padding-top:10vh;
   margin:10px auto;
    font-size:150%;
    display: inline-block;
    border-bottom:2px solid #14a76c;
}
.Cart a{
    text-decoration: none;
    color:#fff;
    padding:0 10px;
}
.Cart .active{
    color:#14a76c;
}
.cartTotal p{
    font-size:130%;
    
}
.cartTotal{
    padding:20px 10px;
    background:-webkit-linear-gradient(left,#14a76c,#0f7a4f);
    background:linear-gradient(to right,#14a76c,#0f7a4f);
    color:#fff;
}
.cartItem{
    border-bottom:1px solid #fff;
    height: auto;
    color:#fff;
    padding:5px auto;
    background-color: #242424;
    text-align: center;
}
.cartItem:hover{
    box-shadow: 0 30px 60px #0b0b0b;
}
.cartItem .image-Box{
    height:160px;
    margin:10px auto;
    width:auto;
}
@media screen and (max-width:660px){
    .cartItem .image-Box{
        display: block;
        height:110px;
        
        
    }
    .cartItem{
        padding-bottom: 10px;
        display: block;
        
    }
    /* .cartItemContent button{
        display: block;
    } */
}
.cartItem .image-Box img{
    height: 100%;
    width:auto;
}
.cartItemContent{
    padding:5px auto;
}
.cartItemContent .cartItemContentHead{
    color:#ff652a;
    font-size: 120%;
}
.cartItemContent .cartItemContentContent{
    font-size:90%;
    color:#f5f5f5;
}
.cartAmount{
    background-color: #ff652a;
    padding:2px 10px;
}
.cartItemContent button{
    background-color: #14a76c;
    border-radius: 200px;
    padding:2px 15px;
    margin:0px 10px;
    border:0;
}
.Offers{
    top:0;
    left:0;
    background-image: -webkit-linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(/static/media/offer.d5ab2f42.png);
    background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(/static/media/offer.d5ab2f42.png);
    background-position:center;
    background-attachment: fixed;
    height:65vh;
    color:#fff;
    width:100vw;
}
@media screen and (max-width:660px){
    .Offers{
        height:40vh;
    }
}
.OffersHead{
    padding-top:10vh;
   margin:10px auto;
    font-size:150%;
    display: inline-block;
    border-bottom:2px solid #14a76c;
}
.Offers a{
    text-decoration: none;
    color:#fff;
    padding:0 10px;
}
.Offers .active{
    color:#14a76c;
}

.flip-card {
    background-color: transparent;
    width: 80vw;
    height: 75vh;
    -webkit-perspective: 1000px;
    margin:10px auto;
    perspective: 1000px;
  }
  @media screen and (max-width:660px){
    .flip-card{

      height:80vh;
    }
  }
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-transition: -webkit-transform 0.6s;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }
  
  .flip-card-front {
    color:#fff;
    background-image: -webkit-linear-gradient(left,rgba(20, 167, 108, 0.3)  ,rgba(13, 106, 69, 0.3)), url("https://blog.ihg.com/wp-content/uploads/2018/02/street-food-japan.png");
    background-image: linear-gradient(to right,rgba(20, 167, 108, 0.3)  ,rgba(13, 106, 69, 0.3)), url("https://blog.ihg.com/wp-content/uploads/2018/02/street-food-japan.png");
    background-position: center;
    background-size: cover;
    z-index: 2000;
  }
  /* rgba(255, 101, 47, 0.4),rgba(234, 61, 0, 0.4) */
  
  .flip-card-back {
    color:#fff;
    background:-webkit-linear-gradient(left,#ff652f,rgba(166, 43, 0, 1));
    background:linear-gradient(to right,#ff652f,rgba(166, 43, 0, 1));
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    z-index: 1000;
  }
  ul{
    margin-top:10%;
  }
  li{
    line-height: 145%;
    letter-spacing: 1px;
    word-spacing: 2px;
    margin:10px 5px;
  }
  .flip-card-back a:link,.flip-card-back a:visited{
    padding-top:60%;
    text-decoration: none;
    color:#fff;
    background-color:#14a86c;
    border-radius: 200px;
    border:0;
    padding:7px 20px;
  }
  .flip-card-back a:hover,.flip-card-back a:active{
    background-color: #0d6a45;
    box-shadow: 0 10px 10px #ea3d00;
  }
  .flip-card-front h1,h2,h3,h4{
    margin:10px auto;
    letter-spacing: 2px;
    font-weight: 200;
    word-spacing: 3px;
    line-height: 145%;
  }
  .flip-card-front h1{
    font-size:300%;
    font-weight: 100;
  }
  .flip-card-front h2{
    font-size:220%;
    text-transform: capitalize;
  }
  .flip-card-front h3{
    font-size: 140%;
    font-weight: 300;
  }

.sk-cube-grid {
    width: 40px;
    height: 40px;
    margin: 100px auto;
  }
  
  .sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #333;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
            animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; 
  }
  .sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  .sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s; }
  .sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  .sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }
  .sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
            animation-delay: 0s; }
  .sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s; }
  .sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s; }
  
  @-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
              transform: scale3D(1, 1, 1);
    } 35% {
      -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1); 
    }
  }
  
  @keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
              transform: scale3D(1, 1, 1);
    } 35% {
      -webkit-transform: scale3D(0, 0, 1);
              transform: scale3D(0, 0, 1);
    } 
  }
