.grab{
    height:50px;
    width:auto;
    border-radius: 200px;
    border:1px solid #ff652f;
    padding:10px 25px;
    text-align: center;
    background-color: #ff652f;
    display: inline-block;
    transition: all 0.4s ease;
    cursor:pointer;
}
.grab a{
    color:#fff;
    text-decoration:none;
}
.grab p{
    color:#fff;
    letter-spacing: 1px;
    font-weight: 300;
    font-size: 110%;
}
.grab:hover,.grab:active{
    background-color:#d93800;
    border:1px solid #d93800;
}
@media screen and (max-width:1170px){
    .grab{
        min-width:60px;
        padding:7px 8px;
        height: 40px;
    }
}