.Order{
    top:0;
    left:0;
    background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url('./serve.png');
    background-position: center;
    background-attachment: fixed;
    height:55vh;
    color:#fff;
    width:100vw;
}
@media screen and (max-width:660px){
    .Order{
        background-size: cover;
    }
}
.OrderHead{
   padding-top:10vh;
   margin:10px auto;
    font-size:150%;
    display: inline-block;
    border-bottom:2px solid #14a76c;
}
.Order a {
    margin:0 10px;
    transition: all ease 0.4s;
}
.Order a:link,.Order a:visited{
    text-decoration: none;
    color:#fff;
}
.Order a:hover,.Order a:active{
    color:#14a76c;
    font-weight: bold;
}
.Order .active{
    color:#ff652f;
    
}
.order-sec{
    background:linear-gradient(to right,#14a76c,#0f7a4f);
}
@media screen and (max-width:660px){
    .OrderHead{
        font-size:180%;
    }
    .Order{
        height: 37vh;;
    }
}