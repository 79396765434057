.stacked {
    margin-top:30px;
    height:200px;
    width: auto;
    min-width:150px;
    transition: all 0.5s ease;
}
.stacked img{
    perspective: 1000;
    opacity: 0.8;
    height:100%;
    box-shadow: 0 5px 5px #5f5f5f;
    width:100%;
    transition: all 0.4s ease;
}
.stackedTop{
    transform: rotate(-10deg);
    display: inline-block;
    padding:4px 10px;
    background-color: #14a76c;
    font-size:170%;
    margin-bottom: 30px;
    color:#fff;
    box-shadow: 0 24px 24px #5f5f5f;
    font-weight: 400;    
    transition: all ease 0.4s;
}
.stackedTop:hover{
    transform: rotate(0deg);
    color:#242424;
    
}
.stacked:hover{
    margin-top:0;
}
.stacked img:hover{

    box-shadow: 0 20px 20px #5f5f5f;
    opacity: 1;
    height:220px;
    z-index:300;
    transform: rotate(-20deg);
}
/* .stacked:hover{
    margin-top:0px;
} */

.stacked-container{
    margin-top:10px;
}